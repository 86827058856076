
.flicking-experience.flicking-camera-grid-experience {
  display: flex;
  position: relative;
  will-change: transform;
  z-index: 1;
  margin-inline: auto;
  min-width: min-content;
  gap: 0px;

}

@media (min-width: 1024px) {
	.flicking-experience.flicking-camera-grid-experience {
		cursor: auto;
	}
}

.flicking-experience .flicking-viewport {
	width: 100%;
}

@media (min-width: 450px) {
  .flicking-experience .flicking-viewport {
    width: 75%;
  }
}

@media (min-width: 700px) {
  .flicking-experience .flicking-viewport {
    width: 90%;
  }
}

@media (min-width: 1280px) {
  .flicking-experience .flicking-viewport {
    width: 75%;
  }
}

.flicking.flicking-camera-grid .flicking-element {
  position: relative;
}
