@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');

@font-face {
  font-family: 'Paralucent-Medium';
  src: url('./assets/fonts/Paralucent-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Articulatcf-extrabold';
  src: url('./assets/fonts/Articulatcf-extrabold.otf') format('opentype');
}

@font-face {
  font-family: 'Urbanist-Medium';
  src: url('./assets/fonts/Urbanist-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Articulatcf-bold';
  src: url('./assets/fonts/Articulatcf-bold.otf') format('opentype');
}

@font-face {
  font-family: 'FormaDJRMicro-Medium';
  src: url('./assets/fonts/FormaDJRMicro-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'FormaDJRMicro-Regular';
  src: url('./assets/fonts/FormaDJRMicro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'FormaDJRMicro-Bold';
  src: url('./assets/fonts/FormaDJRMicro-Bold.ttf') format('truetype');
}

body {
  background: #F7F7F7;
  font-family: 'DM Sans', sans-serif;
}

.accordion-animation {
  overflow: hidden;
}

.accordion-animation[data-state='open'] {
  animation: slideDown 300ms ease-out;
}

.accordion-animation[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}