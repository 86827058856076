.flicking-camera {
  display: flex;
  flex-direction: row;
  gap: 0.875em;
  height: 100%;
  position: relative;
  width: 100%;
  will-change: transform;
  z-index: 1;

}


.flicking-viewport {
  margin-left: auto;
  margin-right: auto;
  transition: height .5s;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.AccordionContent[data-state='open'] > svg {
  transform: rotate(-45deg);
}

.AccordionContent[data-state='closed'] > svg {
  transform: rotate(0deg);
}