.bg-tokenomics {
  background: linear-gradient(103deg, #651937 7.73%, #FD778F 55.76%, #FFD2B8 96.67%);
  backdrop-filter: blur(15px);
}

.donutchart {
  transform: rotateZ(60deg) scaleX(-1);
}

.donutchart-innertext {
  display: none;
}

.donutchart-arcs-path {
  transition: all 0.2s ease-in-out;
  opacity: 1 !important;
}

.donutchart-arcs-path:hover {
  opacity: 1 !important;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  z-index: 1;
}