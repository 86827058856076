.hero-form-field[type="number"]::-webkit-outer-spin-button,
.hero-form-field[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hero-form-field[type=number] {
  -moz-appearance: textfield;
}

.marquee {
  position: relative;
}

.rfm-marquee,
.rfm-initial-child-container {
	min-width: max-content;
  gap: 64px;
  padding-left: 45px;
}

.marquee::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  width: 203px;
  background: linear-gradient(-90deg, rgba(247, 247, 247, 0) 0%, #f7f7f7 100%);
  z-index: 2;
}

.marquee::after {
  position: absolute;
  content: '';
  right: 0;
  top: 0;
  bottom: 0;
  width: 203px;
  background: linear-gradient(90deg, rgba(247, 247, 247, 0) 0%, #F7F7F7 100%);
  z-index: 1;
}

@media (max-width: 640px) {
  .marquee::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(-90deg, rgba(251, 244, 241, 0.00) 0%, #FBF4F1 100%);
    z-index: 2;
  }
  
  .marquee::after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background: linear-gradient(90deg, rgba(251, 244, 241, 0.00) 0%, #FBF4F1 100%);
    z-index: 1;
  }
}

.rfm-initial-child-container,
.rfm-marquee {
  width: 100%;
  justify-content: space-around;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -2;
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -2;
}