.single-chart {
  position: relative;
  width: 15.5em;
  height: 15.5em;
  justify-content: space-around;
}

.circular-chart {
  display: block;
}

.circle-bg {
  fill: none;
  stroke: #FFFFFF26;
  stroke-width: 1.8;
}

.circle {
  fill: none;
  stroke-width: 1.4;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart .circle {
  stroke: #fff;
}

.percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.blur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  height: 85%;
  border-radius: 50%;
  z-index: -1;
}