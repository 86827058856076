.main-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	width: 100%;
}
.wrapper {
	width: calc(100% + 450px);
  display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	height: 39em;
	margin-inline: -200px;
}
